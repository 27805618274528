import React from "react"
import { StaticImage, getImage} from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Banner from "../components/banner"
import TrustedBy from "../components/hireVa/trusted"
import SaveTime from "../components/hireVa/savetime"
import GoodChoice from '../components/hireVa/goodchoice'
import PostingRole from '../components/hireVa/postingrole'
import Assistant from '../components/hireVa/assistant'
import Booking from '../components/hireVa/booking'

import Testimonials from "../components/testimonials"
import Faqs from "../components/faqs.js"
import GetInTouch from  '../components/hireVa/getintouch'
import { useStaticQuery, graphql, Link } from "gatsby"



const MarketingService = ({ data }) => { 

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };

      const banner_title  = ['Fully Managed', 3000, 'Fully Trained ', 2000];
      const banner_button = 'Hire Vetted Virtual Assistant';

return (

    <Layout>
<Seo title="Find the best freelance Virtual Assistants as per Demand  " />


<Banner title = {banner_title} sticky_first = "Hire" sticky_second  = "Marketing" button = {banner_button} />
<section className = "inro" >
<div className = "container">
  <p>All of our marketing virtual assistants are trained in exclusive in house boot camps</p>
</div>
</section>

{/* 
        <div className = "vr_banner inner">
        <StaticImage
                            src="../images/marketing-banner.png"
                            quality = "90"
                            layout = "fullWidth"
                            alt="A Gatsby astronaut"
                            placeholder="tracedSVG"
                            />

        <div className = "details container">
        <div className = "details_wrap">

            <h1>Let Marketing VA handle your Web & Social Channels</h1>
            <p>
            Delegate the marketing work to Marketing VA. Hire our Global Marketing VA, they are flexible and Expert in all kind of Market Work. 
            </p>
            <Link to = "https://api.leadconnectorhq.com/widget/appointment/generalcalendar-estorecompany/discoverycall">hire Marketing VA</Link>
        </div>
        </div>


        </div>
*/}

<section className = "trained serv_det">

    <div className="container">

     
        <ul>

        <li>
        <StaticImage
            src="../images/HubSpot.png"
            quality = "100"
          
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>HubSpot</h4>

        </li>


        
        <li>
        <StaticImage
            src="../images/AIOSEO.png"
            quality = "100"
          
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>AIOSEO</h4>

        </li>



        <li>
        <StaticImage
            src="../images/Proof.png"
            quality = "100"
          
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Proof</h4>

        </li>



        <li>
        <StaticImage
            src="../images/Slack.png"
            quality = "100"
          
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Slack</h4>

        </li>



        <li>
        <StaticImage
            src="../images/Trello.png"
            quality = "100"
          
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Trello</h4>

        </li>

        
        <li>
        <StaticImage
            src="../images/Mailchimp_.png"
            quality = "100"
          
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Mailchimp</h4>

        </li>

    </ul>

    </div>


</section>
<TrustedBy/>

<SaveTime/>




<GoodChoice/>

<section className = "trained serv_det">

    <div className="container">

      <h1>WHAT CAN A MARKETING VIRTUAL ASSISTANT DO?</h1>
      <p>Many businesses and entrepreneurs find they eventually need help with growing their online 
          audience, selling their digital services and managing their online businesses. It’s not as simple as 
          paying for ads on Google or Facebook; there are a dozen and one ways to push your brand forward and grow your
           audience online. Marketing VA goal is to get their product in front of as many people as possible, gain a loyal
            following and ultimately turn those followers into buyers. 
          This is what our marketing VA do.</p>


<div className = "row">

        <ul>

        <li>
        <StaticImage
            src="../images/video-editing.png"
            quality = "100"
          width = "80"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Basic Video Editing</h4>

        </li>


        
        <li>
        <StaticImage
            src="../images/social-media-content.png"
            quality = "100"
            width = "80"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Social Media Content</h4>

        </li>



        <li>
        <StaticImage
            src="../images/fb-groups.png"
            quality = "100"
            width = "80"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Manage Facebook Groups</h4>

        </li>



        <li>
        <StaticImage
            src="../images/respond-message.png"
            quality = "100"
            width = "80"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Respond to messages & comments</h4>

        </li>



        <li>
        <StaticImage
            src="../images/social-post.png"
            quality = "100"
            width = "80"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Social Media Posts</h4>

        </li>
        <li>
        <StaticImage
            src="../images/schedule-youtube.png"
            quality = "100"
            width = "80"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Schedule YouTube Videos</h4>

        </li>

        <li>
        <StaticImage
            src="../images/media-research.png"
            quality = "100"
            width = "80"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Social Media Research</h4>

        </li>
        <li>
        <StaticImage
            src="../images/upload-insta.png"
            quality = "100"
            width = "80"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Upload Instagram & Facebook Stories</h4>

        </li>
    </ul>
    </div>


    </div>


</section>



<section className = "service_package">
<h1>CHOOSE  YOUR PLAN</h1>
<div className = "container">

    

<div class="naccs">
  <div class="grid">
   <div class="gc gc--1-of-3">
    <div class="menu">
     <div class="active"><span class="light"></span><span>Part Time VA</span></div>
     <div><span class="light"></span><span>Dedicated VA</span></div>
   
    </div>
   </div>
   <div class="gc gc--2-of-3">
    <ul class="nacc">
     <li class="active">
      <div className = "inner_data">

        <div className = "list">
        <h2>Part Time VA</h2>
          <span><i class="fa fa-check"></i> Can effectively run Cold email  campaigns</span>
          <span><i class="fa fa-check"></i> Ability to manage social media presence</span>
          <span><i class="fa fa-check"></i> Proficient with Graphic design and video editing   </span>
          <span><i class="fa fa-times"></i> Effective lead generation for your business</span>
          <span><i class="fa fa-check"></i> Matching US market benchmarks</span>
          <span><i class="fa fa-check"></i> Perfect English, both spoken and written</span>
        
          <span><i class="fa fa-times"></i> CRM Management</span>
          <span><i class="fa fa-times"></i> SEO Optimisation</span>
          <span><i class="fa fa-times"></i> PPC Campaign Management</span>
          <span><i class="fa fa-check"></i> Email Marketing</span>
          <span><i class="fa fa-check"></i> Content Marketing & Digital PR</span>
          <span><i class="fa fa-times"></i> Online Advertising</span>
        </div>

        <div className = "start_now">
          <h2>$20</h2>
          <span>Per Hour</span>
          <Link to = "https://api.leadconnectorhq.com/widget/appointment/generalcalendar-estorecompany/discovery-call-for-virtual-assistant">start now</Link>
        </div>
       
     
      </div>
     </li>
     <li>
     <div className = "inner_data">

<div className = "list">
<h2>Dedicated VA</h2>
<span><i class="fa fa-check"></i> Can effectively run Cold email  campaigns</span>
          <span><i class="fa fa-check"></i> Ability to manage social media presence</span>
          <span><i class="fa fa-check"></i> Proficient with Graphic design and video editing   </span>
          <span><i class="fa fa-check"></i> Effective lead generation for your business</span>
          <span><i class="fa fa-check"></i> Matching US market benchmarks</span>
          <span><i class="fa fa-check"></i> Perfect English, both spoken and written</span>
        
          <span><i class="fa fa-check"></i> CRM Management</span>
          <span><i class="fa fa-check"></i> SEO Optimisation</span>
          <span><i class="fa fa-check"></i> PPC Campaign Management</span>
          <span><i class="fa fa-check"></i> Email Marketing</span>
          <span><i class="fa fa-check"></i> Content Marketing & Digital PR</span>
          <span><i class="fa fa-check"></i> Online Advertising</span>
</div>

<div className = "start_now">
  <h2>$17</h2>
  <span>Per Hour</span>
  <Link to = "https://api.leadconnectorhq.com/widget/appointment/generalcalendar-estorecompany/discoverycall">start now</Link>
</div>


</div>


     </li>
   
    </ul>
   </div>
  </div>
 </div>

</div>


</section>





<PostingRole/>



<Assistant/>

<Booking/>

<Testimonials/>

    <Faqs/>
<GetInTouch/>

 </Layout>

)


}

export default MarketingService;